import React from "react";
import tw from "twin.macro";
import { LogoLink } from "components/headers/light.js";


import logo from "images/MainLogo.png";


const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
const PrimaryNavLink = tw(
    NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;

export default function Nav() {

    return (
        <NavRow>
            <LogoLink href="/">
                <img src={logo} alt="" />
                HydroNest
            </LogoLink>
            <div tw="flex flex-wrap justify-center lg:justify-end items-center -mr-12">
                <NavLink href="/">
                    Home
                </NavLink>
                <NavLink href="/about-us">
                    About Us
                </NavLink>
                <PrimaryNavLink href="/sign-up">
                    Sign Up
                </PrimaryNavLink>
            </div>
        </NavRow>
    );
}