import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Nav from "nav";
export default () => {
  return (
    <AnimationRevealPage>
      <Nav />
      <Pricing />
    </AnimationRevealPage>
  );
};
