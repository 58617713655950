import React from "react";

import MainLandingPage from "MainLandingPage.js";
import AboutUs from "AboutUs";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Pricing from "Pricing";

export default function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/sign-up" element={<Pricing />} />
          <Route path="/About-Us" element={<AboutUs />} />
          <Route path="/" element={<MainLandingPage />} />
        </Routes>
      </Router>
    </>
  );
}
